import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { McConfigService } from '../services/mc-config.service';
import { ILoginUser } from './model';
import { IHttpApiRet } from '../services/mc-http.service';
import { StatusCode } from '../services/code';
import { McStorageService } from '../services/mc-storage.service';
import { McUtils } from '../services/mc-utils';
import { IDict } from '../services/mc.interface';

declare const window: any;


@Injectable({ providedIn: 'root' })
export class AuthService {
  returnUrl: string;
  private current_user_subject: BehaviorSubject<ILoginUser>;

  constructor(private http: HttpClient, private mcConfig: McConfigService, private mcStorage: McStorageService) {
    this.current_user_subject = new BehaviorSubject<ILoginUser>(null);

    this.get_storage_jwt().then(ret => {
      // console.log('jwttt', ret);
      if (ret) {
        // this.current_user_subject = new BehaviorSubject<ILoginUser>(ret);
        this.current_user_subject.next(ret);
      }
    });

  }

  get user_change(): Observable<ILoginUser> {
    return this.current_user_subject.asObservable();
  }

  get userid(): string {
    return this.current_user_subject.value.user.UserId;
  }

  get department(): IDict<number, string> {
    return this.current_user_subject.value.user?.department;
  }

  get dep_info(): IDict<number, string> {
    return this.current_user_subject.value.user?.dep_info;
  }

  get current_user(): ILoginUser {
    return this.current_user_subject.value;
  }

  set current_user(value: ILoginUser) {
    this.current_user_subject.next(value);
  }

  // private getRequestOptions() {
  //   const headers = new Headers({
  //     'Content-Type': 'application/json',
  //   });
  //
  //   return new RequestOptions({headers, withCredentials: true});
  // }


  get_storage_jwt() {
    return this.mcStorage.get(`${this.mcConfig.app_name}_token`);
  }

  login(code: string, state: string, agent: string, camouflage: string) {
    console.log('in--1-login', camouflage);
    // {withCredentials: true}   fakeuser: 'zhaofei', 'sunrui' 'caimmy'
    // 审核 fakeuser: 'shenxiaolin' luyaxian
    const post_data: { [key: string]: string } = { code, state, agent };
    // post_data['fakeuser'] = 'long';
    if (camouflage && camouflage !== '') {
      post_data.camouflage = 'FZ1D.h^GYHn!1UN6P?_a:' + camouflage;
    }
    return this.http.post<IHttpApiRet<ILoginUser>>(`${this.mcConfig.api_root}/public/oauth`, post_data)
      .pipe(map(ret => {
        return new Promise<IHttpApiRet<ILoginUser>>((resolve, reject) => {
          if (ret._code_ === StatusCode.SUCCESS) {
            //   delete ret._code_;
            //   delete ret._msg_;
            const user_data: ILoginUser = ret.data;
            user_data.h5ver = McConfigService.version;
            if (user_data.user.detail_info) {
              user_data.user.department = McUtils.deep_copy(user_data.user.detail_info.department);
              delete user_data.user.detail_info;
            }
            if (user_data.console_debug === '1') {
              // 打开console
              window.vConsole = new window.VConsole();
            }
            // user_data.exp =  Math.round(+new Date()/1000)+20*60*60;
            this.mcStorage.set(`${McConfigService.app_name}_token`, user_data).then();
            console.log('user_data11', user_data);
            this.current_user_subject.next(user_data);
            // return {_code_: StatusCode.SUCCESS, _msg_: ''};
          }
          console.log('loginret', ret);
          resolve(ret);
        });
        // return ret;
      }));
  }

  logout(fn?: () => void) {
    // remove user from local storage to log user out
    this.mcStorage.remove(`${McConfigService.app_name}_token`).then(() => {
      this.current_user_subject.next(null);
      console.log('remove-logout', `${McConfigService.app_name}_token`);
      if (fn) {
        fn();
      }
    });
  }

  /**
   * 外部人员登录
   */
  user_outsource_login(login_id: string, password: string) {
    return this.http.post<IHttpApiRet<ILoginUser>>(`${this.mcConfig.api_root}/public/outsourcing/user/login`,
      { login_id, password })
      .pipe(map(ret => {
        return new Promise<IHttpApiRet<ILoginUser>>((resolve, reject) => {
          if (ret._code_ === StatusCode.SUCCESS) {
            //   delete ret._code_;
            //   delete ret._msg_;
            // console.log('save', `${McConfigService.app_name}_token`, user_data);
            const user_data: ILoginUser = ret.data;
            user_data.h5ver = McConfigService.version;
            user_data.type = 'outsource';
            if (user_data.user.detail_info) {
              delete user_data.user.detail_info;
            }
            this.mcStorage.set(`${McConfigService.app_name}_token`, user_data).then();
            this.current_user_subject.next(user_data);
            // return {_code_: StatusCode.SUCCESS, _msg_: ''};
          }
          console.log('loginret', ret);
          resolve(ret);
        });
        // return ret;
      }));
  }


}
